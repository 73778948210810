import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../head-container';

const ResultComponent = ({ setScreenNumber }) => {
  return (
    <div className={styles.container}>
      <HeadContainer setScreenNumber={setScreenNumber} />
      <p className={styles.title}>Дякуємо за відгук!</p>
      <p className={styles.subtitle}>
        Твій мобільний рахунок буде поповнено найближчим часом.
      </p>
      <p className={styles.description}>
        Долучайся у Телеграм, щоб бути на зв'язку зі своїм брендом.
      </p>
    </div>
  );
};

export default ResultComponent;
