import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

export default function Dot({ item, setDifferencesFound, step, direction }) {
  const [isFound, setIsFound] = useState(false);

  useEffect(() => {
    if (direction === 'forward') {
      setIsFound(false);
    } else {
      setIsFound(true);
    }
  }, [step, direction]);

  const handleClick = () => {
    setIsFound(true);
    setDifferencesFound((prev) => prev + 1);
  };

  return (
    <div
      className={classNames(styles.point, isFound ? styles.active : null)}
      style={item}
      onClick={handleClick}
    />
  );
}
