import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../head-container';

const OtpComponent = ({ setScreenNumber }) => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [timeLeft, setTimeLeft] = useState(60);
  const [isResendAvailable, setIsResendAvailable] = useState(false);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendAvailable(true);
    }
  }, [timeLeft]);

  const handleResend = () => {
    setTimeLeft(60);
    setIsResendAvailable(false);
    console.log('Надсилання нового коду...');
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    let newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      let newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);

      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  return (
    <div className={styles.container}>
      <HeadContainer setScreenNumber={setScreenNumber} />
      <p className={styles.title}>
        Ми надіслали повідомлення на номер 380*********
      </p>
      <p className={styles.description}>
        Для підтвердження номеру введи код із SMS
      </p>
      <div className={styles.otpContainer}>
        {otp.map((data, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={data}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onChange={(e) => handleChange(e.target, index)}
            onFocus={(e) => e.target.select()}
            autoComplete="one-time-code"
          />
        ))}
      </div>
      {isResendAvailable ? (
        <button className={styles.resendButton} onClick={handleResend}>
          Отримати код
        </button>
      ) : (
        <div className={styles.timer}>
          Отримати код повторно через{' '}
          {String(Math.floor(timeLeft / 60)).padStart(2, '0')}:
          {String(timeLeft % 60).padStart(2, '0')}
        </div>
      )}
    </div>
  );
};

export default OtpComponent;
