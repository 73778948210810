import React, { useContext, useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../head-container';
import { DataLayerEventContext } from '../../contexts/dataLayerEventContext';
import PhoneInputComponent from './components/phone-input-component';
import OtpComponent from './components/otp-component';
import ResultComponent from './components/result-component';
import { getTrackBackground, Range } from 'react-range';

const factoriesList = [
  'ПрАТ "Філіп Морріс Україна"',
  'АТ "Імперіал Тобакко Продакшн" на замовлення',
  '"Філіп Морріс С.А."',
  'Інше'
];

const RangeItem = ({ rangeValue, setRangeValue, labelText }) => {
  return (
    <>
      <label className={styles.rangeTitle}>{labelText}</label>
      <Range
        step={1}
        min={1}
        max={5}
        values={rangeValue}
        ariaLabel="Дизайн пачки"
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              display: 'flex',
              position: 'relative',
              height: '22px',
              width: '100%',
              background: getTrackBackground({
                values: rangeValue,
                colors: ['#2E0073', '#955DD6'],
                min: 1,
                max: 5
              }),
              borderRadius: '5px'
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => <div {...props} className={styles.thumb} />}
        onChange={(values) => setRangeValue(values)}
      />
      <div className={styles.rangeItemList}>
        <span>1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
        <span>5</span>
      </div>
    </>
  );
};

const ReviewScreen = ({ setScreenNumber, setSubmitReviewScreenNumber }) => {
  const { sendEvent } = useContext(DataLayerEventContext);
  const [designRangeValue, setDesignRangeValue] = useState([5]);
  const [filterRangeValue, setFilterRangeValue] = useState([5]);
  const [tasteQualitiesRangeValue, setTasteQualitiesRangeValue] = useState([5]);
  const [comment, setComment] = useState('');
  const [clientId, setClientId] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [selectedFactory, setSelectedFactory] = useState('Оберіть зі списку');
  const [isOpenFactoriesSelect, setIsOpenFactoriesSelect] = useState(false);
  const [isError, setIsError] = useState(true);
  const [phoneNumberScreen, setPhoneNumberScreen] = useState(false);
  const [otpScreen, setOtpScreen] = useState(false);
  const [isOpenResult, setIsOpenResult] = useState(false);
  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);

  const onSubmitReview = async () => {
    console.log('submit');
  };

  const handleRemoveImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    const newPreviews = previews.filter((_, i) => i !== index);

    setImages(newImages);
    setPreviews(newPreviews);
  };

  const handleUploadFile = async (event) => {
    if (previews?.length >= 10 && images?.length >= 10) return;

    const filesList = Array.from(event.target.files).slice(0, 10);

    setImages((prevImages) => [...prevImages, ...filesList].slice(0, 10));

    const newPreviews = filesList.map((file) => URL.createObjectURL(file));
    setPreviews((prevPreviews) =>
      [...prevPreviews, ...newPreviews].slice(0, 10)
    );
  };

  return (
    <div className={styles.container}>
      {!phoneNumberScreen && !otpScreen && !isOpenResult && (
        <div className={styles.reviewContainer}>
          <HeadContainer setScreenNumber={setScreenNumber} />
          <p className={styles.title}>
            Нам дуже важливий твій відгук про продукт, щоб стати краще
          </p>
          <p className={styles.subtitle}>
            Залишай відгук і отримуй поповнення мобільного рахунку на 20 грн за
            приділений час
          </p>
          <p className={styles.subtitleDescription}>
            (опція поповнення доступна раз на місяць)
          </p>
          <RangeItem
            labelText="Дизайн пачки"
            rangeValue={designRangeValue}
            setRangeValue={setDesignRangeValue}
          />
          <RangeItem
            labelText="Смакові властивості"
            rangeValue={tasteQualitiesRangeValue}
            setRangeValue={setTasteQualitiesRangeValue}
          />
          <div className={styles.factoryContainer}>
            <p>Фабрика (зазначено на пачці)</p>
            <div
              className={styles.selectContainer}
              onClick={() => setIsOpenFactoriesSelect(!isOpenFactoriesSelect)}
            >
              <p
                onClick={() => setIsOpenFactoriesSelect(!isOpenFactoriesSelect)}
              >
                {selectedFactory}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="9"
                viewBox="0 0 13 9"
                fill="none"
              >
                <path
                  d="M7.27406 8.05362C6.87395 8.5428 6.12605 8.5428 5.72594 8.05362L0.474528 1.63311C-0.0595353 0.980156 0.405036 6.31416e-07 1.24859 7.05162e-07L11.7514 1.62335e-06C12.595 1.69709e-06 13.0595 0.980156 12.5255 1.63311L7.27406 8.05362Z"
                  fill="#461B77"
                />
              </svg>
              {isOpenFactoriesSelect && (
                <div className={styles.factoriesList}>
                  {factoriesList.map((factory) => (
                    <p onClick={() => setSelectedFactory(factory)}>{factory}</p>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className={styles.commentContainer}>
            <label>Твій коментар:</label>
            <textarea
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              placeholder="Залиште свій коментар"
            />
          </div>
          <div className={styles.imageExportContainer}>
            <label>Додай фото продукту (можна декілька):</label>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="upload-image"
              multiple
              type="file"
              className="w-full h-full"
              onChange={handleUploadFile}
            />
            <label htmlFor="upload-image">
              <img src="/images/image-export.png" alt="image-export" />
            </label>
          </div>
          {previews?.length > 0 && (
            <div className={styles.previewImageList}>
              {previews.map((preview, index) => (
                <div className={styles.imageItem} key={index}>
                  <img src={preview} alt={`Preview ${index}`} />
                  <img
                    src="/images/remove-image-icon.png"
                    alt="remove-icon"
                    onClick={() => handleRemoveImage(index)}
                  />
                </div>
              ))}
            </div>
          )}
          <div className={styles.infoContainer}>
            <label>
              Додай фото пачки, що допоможуть ідентифікувати проблему та партію
              продукту. Нижче приклад, головне — це чітке зображення інформації
              на дні пачки.
            </label>
            <img src="/images/review-info.png" alt="reviewImage" />
          </div>
          {isError && <p className={styles.error}>Заповни всі поля</p>}
          <button
            className={styles.menuButton}
            style={{ marginTop: 11 }}
            onClick={() => {
              setIsSubmitDisabled(true);
              onSubmitReview();
            }}
            disabled={isSubmitDisabled}
          >
            Залишити відгук
          </button>
          <button
            className={styles.reviewButton}
            style={{ marginTop: 14 }}
            onClick={() => setScreenNumber(1)}
          >
            Повернутися до меню
          </button>
        </div>
      )}
      {phoneNumberScreen && !otpScreen && !isOpenResult && (
        <PhoneInputComponent setScreenNumber={setScreenNumber} />
      )}
      {!phoneNumberScreen && !isOpenResult && otpScreen && (
        <OtpComponent setScreenNumber={setScreenNumber} />
      )}
      {isOpenResult && !phoneNumberScreen && !otpScreen && (
        <ResultComponent setScreenNumber={setScreenNumber} />
      )}
    </div>
  );
};

export default ReviewScreen;
