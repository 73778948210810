import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../lm-d-s/head-container';
import WarningFooter from '../../lm-d-s/warning-footer/WarningFooter';

const lineList = [
  {
    title: 'L&M Loft Splash',
    pack: '/images/packs/lm/loft-splash.png',
    background: `url("/images/lm-special/new-background.png")`,
    tobaccoFirst: 'Вміст смол, мг - 5',
    tobaccoSecond: 'Вміст нікотину, мг - 0.4',
    filterIcon: '/images/lm-special/filter.png',
    isDarkButton: false,
    isNewMixture: true,
    newMixtureText: 'Абсолютно нова тютюнова суміш',
    characteristicInfoList: [
      {
        title: 'Формат ',
        description: '- King Size Slim (Кінг сайз слім)'
      },
      {
        title: 'Кількість сигарет у пачці ',
        description: '– 20'
      },
      {
        title: 'Кількість пачок у блоці ',
        description: '– 10'
      }
    ],
    characteristicListTitle: (
      <div>
        <p>
          Оригінальний Recessed<sup>тм</sup> фільтр
        </p>
        <p>
          Фільтр складається з двох <br /> частин:
        </p>
      </div>
    ),
    filterCharacteristicList: [
      {
        description: <span>Ацетатна частина</span>,
        bordered: true
      },
      {
        description: (
          <span>
            Ущільнена повітряна камера з ацетатною стінкою, що робить смак
            м'яким і більш збалансованим
          </span>
        ),
        bordered: true
      }
    ]
  },
  {
    title: 'Chesterfield Compact Orange',
    pack: '/images/packs/chesterfield/orange.png',
    background: `url("/images/lm-special/new-background.png")`,
    tobaccoFirst: 'Вміст смол, мг - 5',
    tobaccoSecond: 'Вміст нікотину, мг - 0.4',
    filterIcon: '/images/lm-special/filter.png',
    isDarkButton: false,
    isNewMixture: true,
    newMixtureText: 'Абсолютно нова тютюнова суміш',
    characteristicInfoList: [
      {
        title: 'Формат ',
        description: '- King Size Slim (Кінг сайз слім)'
      },
      {
        title: 'Кількість сигарет у пачці ',
        description: '– 20'
      },
      {
        title: 'Кількість пачок у блоці ',
        description: '– 10'
      }
    ],
    characteristicListTitle: (
      <div>
        <p>
          Оригінальний Recessed<sup>тм</sup> фільтр
        </p>
        <p>
          Фільтр складається з двох <br /> частин:
        </p>
      </div>
    ),
    filterCharacteristicList: [
      {
        description: <span>Ацетатна частина</span>,
        bordered: true
      },
      {
        description: (
          <span>
            Ущільнена повітряна камера з ацетатною стінкою, що робить смак
            м'яким і більш збалансованим
          </span>
        ),
        bordered: true
      }
    ]
  }
];

const ArrowSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="7"
      viewBox="0 0 4 7"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.816411 0.139209L3.85993 3.16392C4.04669 3.34953 4.04669 3.65047 3.85993 3.83608L0.81641 6.86079C0.629645 7.0464 0.326839 7.0464 0.140073 6.86079C-0.0466919 6.67518 -0.0466919 6.37424 0.140073 6.18863L2.84542 3.5L0.140074 0.811367C-0.0466914 0.625755 -0.0466914 0.32482 0.140074 0.139209C0.326839 -0.0464028 0.629646 -0.0464028 0.816411 0.139209Z"
        fill="white"
      />
    </svg>
  );
};

const WelcomeScreen = ({ setScreenNumber, handleSelectPack }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <HeadContainer setScreenNumber={setScreenNumber} />
        <p className={styles.title}>L&M та Chesterfield</p>
        <p className={styles.subtitle}>з абсолютно новою тютюновою сумішшю</p>
        <div className={styles.packList}>
          {lineList.map((pack) => (
            <div
              className={styles.packItem}
              onClick={() => handleSelectPack(pack)}
            >
              <img src={pack?.pack} alt={pack?.title} />
              <div className={styles.name}>
                <span>{pack?.title}</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <circle cx="13.5" cy="13.5" r="13" stroke="white" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.6328 7.25853L18.7199 12.8759C19.0934 13.2206 19.0934 13.7794 18.7199 14.1241L12.6328 19.7415C12.2593 20.0862 11.6537 20.0862 11.2801 19.7415C10.9066 19.3968 10.9066 18.8379 11.2801 18.4932L16.6908 13.5L11.2801 8.50682C10.9066 8.16212 10.9066 7.60324 11.2801 7.25853C11.6537 6.91382 12.2593 6.91382 12.6328 7.25853Z"
                  fill="white"
                />
              </svg>
            </div>
          ))}
        </div>
      </div>
      <button className={styles.menuButton} onClick={() => setScreenNumber(0)}>
        Повернутися назад
      </button>
      <div className={styles.footer}>
        <WarningFooter />
      </div>
    </div>
  );
};

export default WelcomeScreen;
