import React, { useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../head-container';

const PhoneInputComponent = ({ setScreenNumber, handleBackButtonClick }) => {
  const [isError, setIsError] = useState(false);

  return (
    <div className={styles.phoneNumberContainer}>
      <HeadContainer setScreenNumber={setScreenNumber} />
      <p className={styles.title}>Введи, будь ласка, свій номер телефону</p>
      <div className={styles.phoneInputContainer}>
        <div className={styles.labelContainer}>
          <p>Код</p>
          <p>Номер телефону*</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.codeContainer}>+380</div>
          <input className={styles.input} />
        </div>
      </div>
      <p className={styles.subtitle}>
        Отримати поповнення за відгук про продукт можна один раз на місяць
      </p>
      <p className={styles.description}>
        *поповнення мобільного телефону можливе для усіх операторів України
        окрім контрактних номерів
      </p>
      <div className={styles.privacyPolicy}>
        <input type="checkbox" id="privacy" name="privacy" />
        <label htmlFor="privacy">
          Я погоджуюсь з <a href="#">положенням про конфіденційність</a> та{' '}
          <a href="#">захист персональних даних</a>
        </label>
      </div>
      {isError && <p className={styles.error}>Заповни всі поля</p>}
      <button className={styles.menuButton} style={{ marginTop: 11 }}>
        Надіслати номер
      </button>
      <button
        className={styles.reviewButton}
        style={{ marginTop: 14 }}
        onClick={handleBackButtonClick}
      >
        Повернутися назад
      </button>
    </div>
  );
};

export default PhoneInputComponent;
