import React from 'react';
import WelcomeChallengeScreen from '../welcome-challenge-screen';
import { useState } from 'react';
import FinalScreen from './final-screen';
import { useEffect } from 'react';
import Progress from './Progress';

export default function DifferencesChallenge({
  setScreenNumber,
  type,
  backgroundImage
}) {
  const [status, setStatus] = useState('start'); // start | in_progress | finished
  const [step, setStep] = useState(0);
  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      const { start, challengeList, finish } = await import(
        `./utils/${type}.js`
      );
      const [_start, _challengeList, _finish] = await Promise.all([
        start,
        challengeList,
        finish
      ]);
      setData({
        start: _start,
        challengeList: _challengeList,
        finish: _finish
      });
    })();
  }, [type]);

  if (status === 'start')
    return (
      <WelcomeChallengeScreen
        setScreenNumber={setScreenNumber}
        backgroundImage={backgroundImage}
        title={data?.start.title}
        description={data?.start.description}
        buttonTitle={data?.start.button}
        setNextScreenNumber={() => setStatus('in_progress')}
      />
    );
  if (status === 'in_progress')
    return (
      <Progress
        list={data?.challengeList}
        setScreenNumber={setScreenNumber}
        backgroundImage={backgroundImage}
        step={step}
        setStep={setStep}
        setStatus={setStatus}
      />
    );
  if (status === 'finished')
    return (
      <FinalScreen
        setScreenNumber={setScreenNumber}
        setNextScreenNumber={() => setScreenNumber(1)}
        title={data?.finish.title}
        description={data?.finish.description}
        image={data?.finish.image}
        backgroundImage={backgroundImage}
      />
    );
}
