import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import global from '../../index.module.scss';
import HeadContainer from '../../head-container';

const OnlyImageChallenge = ({
  onlyChallenge = false,
  welcomeTitle,
  welcomeDescription,
  challengeList,
  setScreenNumber,
  backgroundImage,
  resultTitle,
  resultDescription,
  setScreenNumberAfterResult
}) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState(challengeList[0]);
  const [isWelcome, setIsWelcome] = useState(!onlyChallenge);
  const [isFinish, setIsFinish] = useState(false);

  return isWelcome && !isFinish ? (
    <div
      className={styles.welcomeContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.titleContainer}>
        <p className={styles.title}>{welcomeTitle}</p>
        <img src="/images/lm-l-green/purple-ellipse.png" alt="ellipse" />
      </div>
      <div className={styles.descriptionContainer}>
        <p className={styles.text}>{welcomeDescription}</p>
      </div>
      <button className={styles.nextButton} onClick={() => setIsWelcome(false)}>
        Дізнатися
      </button>
    </div>
  ) : !isWelcome && !isFinish ? (
    <div
      className={styles.imageSelectContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.stepToolbar}>
        {challengeList.map((item, index) => (
          <div
            className={classNames(
              styles.stepItem,
              index === challengeStep && styles.activeStepItem
            )}
          />
        ))}
      </div>
      {selectQuestion && (
        <div className={styles.questionItem}>
          <span className={styles.titleQuestion}>{selectQuestion.title}</span>
          <img src={selectQuestion.image} alt="#" />
          <div className={styles.descriptionContainer}>
            {selectQuestion.description}
          </div>
        </div>
      )}
      <div className={styles.buttonContainer}>
        {challengeStep !== 0 && (
          <button
            className={global.reviewButton}
            onClick={() => {
              setSelectQuestion(challengeList[challengeStep - 1]);
              setChallengeStep(challengeStep - 1);
            }}
          >
            Назад
          </button>
        )}
        <button
          className={styles.nextButton}
          onClick={() => {
            if (challengeList.length > challengeStep + 1) {
              setSelectQuestion(challengeList[challengeStep + 1]);
              setChallengeStep(challengeStep + 1);
            } else {
              if (!!onlyChallenge) setScreenNumberAfterResult();
              setIsFinish(true);
            }
          }}
        >
          Далі
        </button>
      </div>
    </div>
  ) : (
    <div
      className={styles.welcomeContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.resultContainer}>
        <img
          src="/images/lm-l-green/only-image-challenge/result.png"
          alt="result-icon"
        />
        <p className={styles.title}>{resultTitle}</p>
        <p className={styles.description}>{resultDescription}</p>
        <button
          className={styles.nextButton}
          onClick={setScreenNumberAfterResult}
        >
          Далі
        </button>
      </div>
    </div>
  );
};

export default OnlyImageChallenge;
