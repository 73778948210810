import React, { createContext, useCallback, useMemo, useRef } from 'react';

const PROJECT_IDS = [
  // LM
  'L&M Double Splash',
  'L&M Loft Sea Blue',
  'L&M Loft Blue',
  'L&M Loft Mix',
  'L&M Loft Green',
  'L&M Loft Purple',
  // PM
  'Novel Mix',
  'Novel Mix Summer',
  'Novel Blue',
  'Novel Silver',
  'Novel Purple',
  // BOND
  'Bond street premium blue',
  'Bond street premium silver',
  'Bond street premium mix',
  // MARLBORO
  'Marlboro touch',
  'Marlboro fine touch',
  // PARLIAMENT
  'Parliament soho compact blue',
  'Parliament soho compact silver',
  'Parliament soho vibe',
  // CHESTERFIELD
  '4 Silver',
  '6 Blue',
  'Retuned',
  // KV
  'KV',
  //GHWS
  'GHWS',
  'hstss1',
  'hstss2',
  'BND-B',
  'BND-R',
  'LM-B',
  'LM-R',
  'MLB-G',
  'MLB-R',
  'MLB-S',
  'Ches-O',
  'Ches-B',
  'Ches-SP',
  'Ches-SR',
  'MLB-T-LE',
  'MLB-F-T-LE',
  'KV2',
  'Ches-O-NB',
  'LM-SPL',
  'KV3',
  'Ches-R'
];

const EVENT_NAME = {
  // LM
  'L&M Double Splash': '',
  'L&M Loft Sea Blue': '',
  'L&M Loft Blue': '',
  'L&M Loft Mix': '',
  'L&M Loft Green': '',
  'L&M Loft Purple': '',
  // PM
  'Novel Mix': '',
  'Novel Mix Summer': '',
  'Novel Blue': '',
  'Novel Silver': '',
  'Novel Purple': '',
  // BOND
  'Bond street premium blue': '',
  'Bond street premium silver': '',
  'Bond street premium mix': '',
  // MARLBORO
  'Marlboro touch': '',
  'Marlboro fine touch': '',
  // PARLIAMENT
  'Parliament soho compact blue': '',
  'Parliament soho compact silver': '',
  'Parliament soho vibe': '',
  // CHESTERFIELD
  '4 Silver': '',
  '6 Blue': '',
  Retuned: '',
  // KV
  KV: '',
  // GHWS
  GHWS: '',
  // HSTSS
  hstss1: '',
  hstss2: '',
  'BND-B': '',
  'BND-R': '',
  'LM-B': '',
  'LM-R': '',
  'MLB-G': '',
  'MLB-R': '',
  'MLB-S': '',
  'Ches-O': '',
  'Ches-B': '',
  'Ches-SP': '',
  'Ches-SR': '',
  'LM-SP': '',
  'LM-SR': '',
  'MLB-T-LE': '',
  'MLB-F-T-LE': '',
  KV2: '',
  'Ches-O-NB': '',
  'LM-SPL': '',
  KV3: '',
  'Ches-R': ''
};

export const DataLayerEventContext = createContext(undefined);

export function DataLayerEventProvider({ children }) {
  const isDevelopment = window.location.host.includes('localhost');
  const projectNameRef = useRef();
  projectNameRef.current = undefined;

  const projectGuard = useCallback((projectName) => {
    return PROJECT_IDS.find((it) => it === projectName);
  }, []);

  const init = useCallback((projectName) => {
    projectNameRef.current = projectName;
  }, []);

  const sendEvent = useCallback(
    (eventName) => {
      if (!projectGuard(projectNameRef.current)) return;
      if (!projectNameRef.current || !eventName) return;
      if (!window.dataLayer) return;

      //if (isDevelopment) return;
      const event = `${EVENT_NAME[projectNameRef.current]}${eventName}`;
      window.dataLayer.push({
        event,
        project_id: projectNameRef.current,
        month: new Date().toLocaleString('en-GB', { month: 'long' })
      });
    },
    [projectGuard, isDevelopment]
  );

  const providerValue = useMemo(() => {
    return {
      init,
      sendEvent
    };
  }, [init, sendEvent]);

  return (
    <DataLayerEventContext.Provider value={providerValue}>
      {children}
    </DataLayerEventContext.Provider>
  );
}
