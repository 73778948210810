import React, { useCallback } from 'react';
import styles from './index.module.scss';
import global from '../../../index.module.scss';
import HeadContainer from '../../../../common/head-container';
import { DataLayerEventContext } from '../../../../contexts/dataLayerEventContext';
import { useContext } from 'react';
import classNames from 'classnames';

const FinalScreen = ({
  setScreenNumber,
  setNextScreenNumber,
  setViewScreenNumber,
  title,
  subtitle,
  description,
  image,
  backgroundImage,
  style = null,
  nextButtonText = null
}) => {
  const { sendEvent } = useContext(DataLayerEventContext);
  const onButtonClickHandler = useCallback(() => {
    setNextScreenNumber();
    sendEvent('exit_challenge');
  }, [sendEvent, setNextScreenNumber]);

  return (
    <div
      className={classNames(
        styles.finalContainer,
        style ? styles[style] : null
      )}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div>
        <div className={styles.imageContainer}>
          <img
            src="/images/adventure/final_ellipse.png"
            alt="blue-ellipse"
            className={styles.ellipse}
          />
          {image && (
            <img className={styles.finalImage} src={image} alt="final-image" />
          )}
        </div>
        <p className={styles.finalTitle}>{title}</p>
        {subtitle && <p className={styles.finalSubtitle}>{subtitle}</p>}
      </div>
      <div className={styles.contentWrapper}>
        <p className={styles.finalDescription}>{description}</p>
        {setViewScreenNumber && (
          <button className={global.menuButton} onClick={setViewScreenNumber}>
            Дивитися
          </button>
        )}
        <button
          className={classNames(
            setViewScreenNumber ? global.reviewButton : global.menuButton,
            styles.button
          )}
          onClick={onButtonClickHandler}
        >
          {nextButtonText ? nextButtonText : 'Далі'}
        </button>
      </div>
    </div>
  );
};

export default FinalScreen;
