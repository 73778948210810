import React, { useContext, useEffect, useState } from 'react';
import { ScreensContext } from '../contexts/screensContext';
import styles from '../components/lm-d-s/index.module.scss';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import FinalScreen from '../components/kv2/final-screen';
import WelcomeScreen from '../components/kv3/welcome-screen';
import CommerceVideoScreen from '../components/commerce-video-screen/CommerceVideoScreen';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import PackScreen from '../components/kv3/pack-screen';

const KV3 = () => {
  const [screenNumber, setScreenNumber] = useState(0);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [selectedPack, setSelectedPack] = useState(null);
  const { init } = useContext(DataLayerEventContext);

  useEffect(() => window.scrollTo(0, 0), [screenNumber]);

  useEffect(() => {
    init('KV3');
  }, [init]);

  const handleSelectPack = (pack) => {
    setSelectedPack(pack);
    setScreenNumber(2);
  };

  const getScreen = (number, cb) => {
    switch (number) {
      case 0: {
        return (
          <CommerceVideoScreen
            videoSrc="/videos/new-special.mp4"
            setScreenNumber={cb}
            nextScreenNumber={1}
            changeScreenImmediately
            skipScreenImmediately
            controls
            buttonStyle="skip-video"
            hasButtonArrow
          />
        );
      }
      case 1: {
        return (
          <WelcomeScreen
            setScreenNumber={cb}
            backgroundImage={`url("/images/lm-special/new-background.png")`}
            handleNextButton={() => setScreenNumber(3)}
            handleSelectPack={handleSelectPack}
          />
        );
      }
      case 2: {
        return (
          <PackScreen
            setScreenNumber={cb}
            selectedPack={selectedPack}
            onNextButtonClick={() => setScreenNumber(3)}
          />
        );
      }
      case 3: {
        return (
          <FinalScreen
            setScreenNumber={cb}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };
  return (
    <div className={styles.pageContainer} id="kv-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default KV3;
