import React, { useContext, useEffect, useState } from 'react';
import { ScreensContext } from '../contexts/screensContext';
import MenuScreen from '../components/lm-d-s/menu-screen';
import styles from '../components/lm-d-s/index.module.scss';
import InfoScreen from '../components/lm-d-s/info-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import CharacteristicScreen from '../components/lm-d-s/characteristic-screen';
import FinalScreen from '../components/lm-d-s/final-screen';
import ReviewScreen from '../components/lm-d-s/review-screen';
import LmLineScreen from '../components/lm-d-s/lm-line-screen';
import ProhibitedChapterScreen from '../components/lm-d-s/prohibited-chapter-screen';
import ResultInfoScreen from '../common/challenge-component/result-info-screen';
import FourOptionsChallenge from '../common/challenge-component/four-options-challenge';
import WelcomeScreen from '../components/lm-d-s/welcome-screen';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import AllBrandsScreen from '../common/all-brands-screen';
import BrandLineScreen from '../common/brand-line-screen';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import QuizChallenge from '../common/challenge-component/quiz-challenge';
import SelectSeasonChallenge from '../common/challenge-component/select-season-challenge';

const menu = [
  {
    id: 'challenge',
    screenNumber: 14,
    name: 'Челендж жовтня',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 15,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: 4,
    name: 'Характеристики сигарет',
    icon: 'images/lm-double-splash/characteristic-menu-icon.svg'
  },
  {
    id: 'info',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/lm-double-splash/info-menu-icon.svg'
  },
  {
    id: 'authority',
    screenNumber: 2,
    name: 'Сигарети з капсулами забороняють?',
    icon: 'images/lm-double-splash/authority-menu-icon.svg'
  },
  {
    id: 'review',
    screenNumber: 12,
    name: 'Залишити відгук про продукт',
    icon: 'images/lm-double-splash/review-menu-icon.svg'
  }
];

const selectedPack = {
  title: 'L&M Loft Splash',
  pack: '/images/packs/lm/loft-splash.png',
  background: `url("/images/lm-special/new-background.png")`,
  tobaccoFirst: 'Вміст смол, мг - 5',
  tobaccoSecond: 'Вміст нікотину, мг - 0.4',
  filterIcon: '/images/parliament/filter.png',
  isDarkButton: false,
  isNewMixture: true,
  newMixtureText: 'Абсолютно нова тютюнова суміш',
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  characteristicListTitle: (
    <div>
      <p>
        Оригінальний Recessed<sup>тм</sup> фільтр
      </p>
      <p>
        Фільтр складається з двох <br /> частин:
      </p>
    </div>
  ),
  filterCharacteristicList: [
    {
      description: <span>Ацетатна частина</span>,
      bordered: true
    },
    {
      description: (
        <span>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      ),
      bordered: true
    }
  ]
};

const LMDS = () => {
  const [screenNumber, setScreenNumber] = useState(0);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => window.scrollTo(0, 0), [screenNumber]);

  useEffect(() => {
    init('L&M Double Splash');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(16);
            }}
            handleBackButton={() => setScreenNumber(4)}
          />
        );
      }
      case 0: {
        return (
          <WelcomeScreen
            setScreenNumber={cb}
            handleBackButton={() => setScreenNumber(1)}
            backgroundImage={`url("/images/lm-double-splash/violet-background.png")`}
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menu}
            backgroundImage={`url("/images/lm-double-splash/background-new.png")`}
          />
        );
      }
      case 2: {
        return (
          <ProhibitedChapterScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 4: {
        return (
          <CharacteristicScreen
            brandId="lm"
            isNewMixture={selectedPack?.isNewMixture}
            title={selectedPack?.title}
            newMixtureText={selectedPack?.newMixtureText}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={selectedPack?.filterCharacteristicList}
            infoList={selectedPack?.characteristicInfoList}
            tobaccoFirst={selectedPack?.tobaccoFirst}
            tobaccoSecond={selectedPack?.tobaccoSecond}
            packImage={selectedPack?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={selectedPack?.background}
            filterIcon={selectedPack?.filterIcon}
            characteristicListTitle={selectedPack?.characteristicListTitle}
            nextButtonTitle="Далі"
          />
        );
      }
      case 5: {
        return (
          <FourOptionsChallenge
            setScreenNumber={setScreenNumber}
            type={'default'}
            backgroundImage={`url("/images/lm-double-splash/background-new.png")`}
          />
        );
      }
      case 7: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(10)}
            title="Сподіваємося"
            subtitle="тобі було цікаво"
            description="Дослухайся до наших порад і зроби зустріч із друзями незабутньою!"
            image="/images/lm-double-splash/challenge/september-challenge/finally-icon.png"
            backgroundImage={`url("/images/lm-double-splash/violet-background.png")`}
          />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} withReviewButton />;
      }
      case 11: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 12: {
        return (
          <ReviewScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(11)}
            SHEET_ID="0"
          />
        );
      }
      case 13: {
        return <LmLineScreen setScreenNumber={cb} />;
      }
      case 14: {
        return (
          <QuizChallenge
            setScreenNumber={() => setScreenNumber(10)}
            type="who-are-you-in-the-company-of-friends"
            backgroundImage={`url("/images/lm-double-splash/background-new.png")`}
            resultTitleFontSize={48}
            style={'lmStyles'}
            hasResultPrev={false}
          />
        );
      }
      case 15: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      case 16: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(10)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };
  return (
    <div className={styles.pageContainer} id="lm-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default LMDS;
