import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../head-container';
import WarningFooter from '../../components/lm-d-s/warning-footer/WarningFooter';
import global from '../../components/lm-d-s/index.module.scss';

const brandsList = [
  {
    id: 'parliament',
    icon: '/images/all-brands/parliament.png',
    title: 'Parliament'
  },
  {
    id: 'marlboro',
    icon: '/images/all-brands/marlboro.png',
    title: 'Marlboro'
  },
  {
    id: 'lm',
    icon: '/images/all-brands/lm.png',
    title: 'L&M'
  },
  {
    id: 'chesterfield',
    icon: '/images/all-brands/chesterfield.png',
    title: 'Chesterfield'
  },
  {
    id: 'bond',
    icon: '/images/all-brands/bond.png',
    title: 'Bond'
  },
  {
    id: 'philip-morris',
    icon: '/images/all-brands/philip-morris.png',
    title: 'Philip Morris'
  }
];

const AllBrandsScreen = ({
  setScreenNumber,
  setBrandLine,
  handleBackButton
}) => {
  return (
    <div className={styles.wrapperContainer}>
      <div className={styles.container}>
        <HeadContainer setScreenNumber={setScreenNumber} />
        <div className={styles.brandsContainer}>
          {brandsList.map((item) => {
            return (
              <div
                className={styles.brandItem}
                onClick={() => setBrandLine(item.id)}
              >
                <div className={styles.iconContainer}>
                  <img src={item.icon} alt={item.icon} />
                </div>
                <p className={styles.title}>{item.title}</p>
                <img
                  src="/images/all-brands/right-arrow.svg"
                  alt="right-arrow"
                />
              </div>
            );
          })}
        </div>
        <button className={styles.backButton} onClick={handleBackButton}>
          Повернутися назад
        </button>
      </div>
      <WarningFooter />
    </div>
  );
};

export default AllBrandsScreen;
